import React from 'react'
import styles from './style.module.css'
import CheckedIcon from 'src/assets/images/checked'
import Button from 'src/components/button'

const VerifySubmitted = () => {
    return (
        <>
            <div className={styles.verificationHeader}>
                <div className={styles.headerReviewStage}>
                    {' '}
                    <CheckedIcon />
                </div>
                Accept terms & policies
            </div>
            <div className={styles.reviewsContainer}>
                <div className={styles.reviewsBox}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            height: '100%',
                            justifyContent: 'center',
                        }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}>
                            <h2 className={styles.titleReviewing}>
                                We’re reviewing your profile.
                            </h2>
                        </div>
                        <div className={styles.reviewingSubtitle}>
                            Once approved you will be able to start placing
                            bids. In the meantime, you can explore our current
                            auctions and explore the platform. This process
                            usually takes 1-2 business days.
                        </div>
                    </div>
                    <div className={styles.reviewsButtonWrapper}>
                        <Button
                        // className={styles.btnVerificationNext}
                        >
                            Explore auctions
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VerifySubmitted
