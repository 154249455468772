import React from 'react'
import styles from './style.module.css'
import checkboxStyles from './checkbox-container/style.module.css';
import CheckboxContainer from './checkbox-container'
import Button from 'src/components/button'
import Form from 'react-bootstrap/Form'

import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useAuth } from 'src/lib/providers/auth'
import { useModal } from 'src/lib/providers/modal';
import { MODAL } from 'src/components/layouts/Layout';
import { FormGroup } from 'react-bootstrap';
import { api } from 'src/lib/api/client';
import { useRefreshUser } from 'src/lib/hooks/auth';

const TermsAndConditions = Yup.object().shape({
    terms: Yup.boolean().isTrue('You need to accept Terms and Conditions'),
    policies: Yup.boolean().isTrue('You need to accept Privacy Policy'),
});

const VerifyTerms = () => {
    const { access } = useAuth();
    const { refreshUser } = useRefreshUser();
    const { openModal } = useModal()
    const handleOpenModal = () => {
        openModal(MODAL.ACCEPT_TERMS)
    }

    const formik = useFormik({
        initialValues: {
            terms: false,
            policies: false,
        },
        validationSchema: TermsAndConditions,
        // validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async values => {
            await api.post('/users/me/accept_tos/', {}, {
                headers: {
                    'Authorization': `Bearer ${access}`
                }
            });
            refreshUser();
        },
    });

    return (
        <>
            <div className={styles.verificationHeader}>
                <div className={styles.headerStage}>04</div>
                Accept terms & policies
            </div>
            <div className={styles.acceptContainer}>
                <div className={styles.acceptBox}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                        <div>
                            <h4 className={styles.titleVerification}>
                                Accept terms & policies
                            </h4>
                        </div>

                        <div className={styles.acceptCheckboxWrapper}>

                            <Form className={checkboxStyles.formCheckbox} onSubmit={formik.handleSubmit}>
                                <div className={checkboxStyles.checkboxContainer}>
                                    <FormGroup>
                                        <Form.Check
                                            className={`${checkboxStyles.checkboxItem} ${checkboxStyles.customCheckbox}`}
                                            reverse
                                            label={
                                                <span>
                                                    I have read and agree to the{' '}
                                                    <span
                                                        className={checkboxStyles.linkText}
                                                        onClick={handleOpenModal}>
                                                        Terms & Conditions
                                                    </span>
                                                </span>
                                            }
                                            name="terms"
                                            type="checkbox"
                                            id="terms"
                                            value={formik.values.terms}
                                            onChange={formik.handleChange}
                                            isInvalid={!!formik.errors.terms}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.terms}
                                        </Form.Control.Feedback>
                                    </FormGroup>
                                    <hr className={checkboxStyles.divider} />

                                    <FormGroup>
                                        <Form.Check
                                            className={`${checkboxStyles.checkboxItem} ${checkboxStyles.customCheckbox}`}
                                            reverse
                                            label={
                                                <span>
                                                    I have read and agree to the{' '}
                                                    <span
                                                        className={checkboxStyles.linkText}
                                                        onClick={handleOpenModal}>
                                                        Privacy Policy
                                                    </span>
                                                </span>
                                            }
                                            name="policies"
                                            type="checkbox"
                                            id="policies"
                                            value={formik.values.policies}
                                            onChange={formik.handleChange}
                                            isInvalid={!!formik.errors.policies}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.policies}
                                        </Form.Control.Feedback>
                                    </FormGroup>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div className={styles.verificationButtonWrapper}>
                        <Button onClick={() => formik.handleSubmit()}>Next</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VerifyTerms
