import Home from '../pages/Home'
import Auction from '../pages/Auction'
import PathConstants from './pathConstants'
import Lot from '../pages/Lot'
import Profile from 'src/pages/Profile'
import AboutUs from '../pages/AboutUs'
import Faq from '../pages/Faq'
import Terms from '../pages/Terms'
import Privacy from '../pages/Privacy'
import VerificationLayout from 'src/components/verification-user'
import VerifyProfileInfo from 'src/components/verification-user/first-step-verification'
import VerifyBank from 'src/components/verification-user/second-step-verification'
import VerifyIdentity from 'src/components/verification-user/third-step-verification'
import VerifyTerms from 'src/components/verification-user/fourth-step-verification'
import VerifySubmitted from 'src/components/verification-user/reviewing-verification'
import ProfileInformationPage from 'src/components/profile-edit-tabs/information'
import UserProfileLayout from 'src/components/profile-edit-tabs'
import PasswordPage from 'src/components/profile-edit-tabs/password'
import DeliveryPage from 'src/components/profile-edit-tabs/delivery'
import PaymentPage from 'src/components/profile-edit-tabs/payment'
import EmailNotificationPage from 'src/components/profile-edit-tabs/email-notification'
import { useAuth } from 'src/lib/providers/auth'
import { Navigate } from 'react-router'
import VerificationPage from 'src/pages/Verification'

const Authorized = ({ children }) => {
    const { isLoggedIn } = useAuth();

    if (!isLoggedIn) return <Navigate to={PathConstants.AUTH} />;
    return children;
}

const routes = [
    { path: PathConstants.HOME, element: <Home /> },
    // {
    //     path: PathConstants.EDIT_PROFILE_INFO,
    //     element: (
    //         <UserProfileLayout>
    //             <ProfileInformationPage />
    //         </UserProfileLayout>
    //     ),
    // },
    // {
    //     path: PathConstants.EDIT_PROFILE_PASSWORD,
    //     element: (
    //         <UserProfileLayout>
    //             <PasswordPage />
    //         </UserProfileLayout>
    //     ),
    // },
    // {
    //     path: PathConstants.EDIT_PROFILE_DELIVERY,
    //     element: (
    //         <UserProfileLayout>
    //             <DeliveryPage />
    //         </UserProfileLayout>
    //     ),
    // },
    // {
    //     path: PathConstants.EDIT_PROFILE_PAYMENT,
    //     element: (
    //         <UserProfileLayout>
    //             <PaymentPage />
    //         </UserProfileLayout>
    //     ),
    // },
    // {
    //     path: PathConstants.EDIT_PROFILE_EMAIL_NOTIFICATION,
    //     element: (
    //         <UserProfileLayout>
    //             <EmailNotificationPage />
    //         </UserProfileLayout>
    //     ),
    // },
    { path: PathConstants.PROFILE, element: <Authorized><Profile /></Authorized> },
    { path: PathConstants.VERIFICATION, element: <Authorized><VerificationPage /></Authorized> },
    { path: PathConstants.PROFILE_EDIT, element: <UserProfileLayout /> },
    { path: `${PathConstants.AUCTION}/:uuid`, element: <Auction /> },
    {
        path: `${PathConstants.AUCTION}/:uuid/:lot_uuid`,
        element: <Lot />,
        name: 'Lot',
    },
    { path: PathConstants.ABOUT_US, element: <AboutUs /> },
    { path: PathConstants.FAQ, element: <Faq /> },
    { path: PathConstants.TERMS, element: <Terms /> },
    { path: PathConstants.PRIVACY, element: <Privacy /> },
    {
        path: PathConstants.VERIFICATION_PROFILE_INFO,
        element: (
            <VerificationLayout>
                <VerifyProfileInfo />
            </VerificationLayout>
        ),
    },
    {
        path: PathConstants.VERIFICATION_BANK,
        element: (
            <VerificationLayout>
                <VerifyBank />
            </VerificationLayout>
        ),
    },
    {
        path: PathConstants.VERIFICATION_IDENTITY,
        element: (
            <VerificationLayout>
                <VerifyIdentity />
            </VerificationLayout>
        ),
    },
    {
        path: PathConstants.VERIFICATION_TERMS,
        element: (
            <VerificationLayout>
                <VerifyTerms />
            </VerificationLayout>
        ),
    },
    {
        path: PathConstants.VERIFICATION_REVIEW,
        element: (
            <VerificationLayout>
                <VerifySubmitted />
            </VerificationLayout>
        ),
    },
]
export default routes
