import React from 'react'

const CheckedIcon = () => {
    return (
        <svg
            width="23"
            height="18"
            viewBox="0 0 23 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1 9.79297L7 15.793L22 0.792969"
                stroke="#FF7F47"
                strokeWidth="2"
            />
        </svg>
    )
}

export default CheckedIcon
